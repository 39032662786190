import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import ISO from "../Components/Assets/Images/iso-22000.png";
import Faasi from "../Components/Assets/Images/pngegg.png";
import SBifrom from "../Components/Assets/Images/spices-board.jpg";
import APEDA from "../Components/Assets/Images/apeda.png";
import Img2 from "../Components/Assets/Images/slider2.jpg";
import Img3 from "../Components/Assets/Images/bulbul-ahmed-AHLXuatgJB0-unsplash.jpg";
import shop1 from "../Components/Assets/Godown_Images/1.jpeg";
import shop2 from "../Components/Assets/Godown_Images/7.jpeg";
import shop3 from "../Components/Assets/Godown_Images/8.jpeg";
import shop4 from "../Components/Assets/Godown_Images/4.jpeg";
import { Data } from "../Json/Data"
import { Outlet, Link } from "react-router-dom";
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRipple
} from 'mdb-react-ui-kit';
function LandingPage() {
    const [products, setProducts] = useState(Data);
    useEffect(() => {
        // Set the products data from the imported JSON file
        setProducts(Data);
        window.scrollTo(0, 0); // Scrolls the window to the top when the route changes
    }, []);


    return (
        <>
            <Header />
            <MDBContainer fluid>
                <div className="padding-container">
                    <section className="mainPage">
                        <MDBRow className="mob-d-flex mob-justify-content-center">
                            <MDBCol md={12} lg={6} sm={12} className="d-flex align-items-center justify-content-center flex-column mob-order-2">
                                <div className="posterImage d-flex align-items-center justify-content-center flex-column  p-3">
                                    <h1 className="pb-3">Spice up your world with Akash Enterprises! Exporting premium chilli variants globally for unforgettable flavors.</h1>
                                    <p >Welcome to <b>Akash Enterprises </b>, your premier destination for the finest chilli variants sourced from around the globe. With a passion for quality and a commitment to excellence, we specialize in exporting a diverse range of chilli peppers to discerning clients worldwide. Our reputation as experts in the field precedes us, as we meticulously select only the highest quality chillies to deliver unparalleled flavors to your table.</p>
                                    <p >At <b>Akash Enterprises </b>, we take pride in our extensive selection of chilli variants, catering to every palate and culinary need. From the fiery heat of the Carolina Reaper to the subtle complexity of the Kashmiri chilli, our range encompasses a spectrum of flavors and intensities to elevate any dish. Whether you're a professional chef seeking to impress your patrons or a home cook looking to add a kick to your meals, our diverse inventory ensures that you'll find the perfect chilli to suit your taste.</p>
                                    <p >With our unwavering commitment to customer satisfaction and stringent quality control measures, <b>Akash Enterprises </b> stands as a trusted partner in the global spice trade. Our dedication to excellence extends beyond the products we offer; it's ingrained in every aspect of our business, from sourcing to packaging to delivery. Join us on a journey of flavor exploration and experience the difference that quality makes with <b>Akash Enterprises </b>.</p>
                                    <div className="d-flex mob-my-3 mob-w-100">
                                        <Link to="/contactus" className="mob-w-50">
                                            <MDBBtn className="shadow-0 rounded-0 mob-w-100" size="lg">Contact Us</MDBBtn>
                                        </Link>
                                        <Link to="/about" className="mob-w-50">
                                            <MDBBtn className="ms-2 shadow-0 rounded-0 mob-w-100" size="lg" outline>Know More</MDBBtn>
                                        </Link>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol md={12} lg={6} sm={12} className="d-flex align-items-center justify-content-center  ">
                                <div className="posterImage d-flex align-items-center justify-content-center mob-order-1">
                                    <img src={Img2} alt="" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </section>

                    <section className="section-2">
                        <MDBRow>
                            <MDBCol md={12} lg={6} sm={12}>
                                <div className="posterImage d-flex align-items-center justify-content-center">
                                    <img src={Img3} alt="" />
                                </div>
                            </MDBCol>
                            <MDBCol className="d-flex align-items-center justify-content-center flex-column">
                                <div className="posterImage    p-3">
                                    <h1 className="pb-2">Explore our premium chili selection. Elevate your dishes with unmatched flavors.</h1>
                                    <h5 className="text-left ">Our Specialties</h5>
                                    <p >Embark on a journey of chili excellence with Akash Enterprises Enterprises, where our years of experience converge to offer an unparalleled selection of premium chili variants. From the renowned S17 Teja to the exotic Tomato chili, each variant in our curated collection is meticulously sourced and guaranteed to exceed your expectations.</p>
                                    <p >At Akash Enterprises Enterprises, we are dedicated to preserving chili tradition while embracing innovation. Our in-depth knowledge and expertise ensure that every chili product we offer reflects the highest standards of quality and craftsmanship. Explore our range and experience the rich diversity of chili flavors, each one telling a unique story of passion and dedication.</p>
                                    <p >Indulge in the artistry of flavor with Akash Enterprises Enterprises, your trusted partner in the pursuit of culinary excellence. Join us as we unlock a world of endless possibilities, where every chili variant promises to elevate your dishes to new heights. Discover the difference that quality makes and elevate your culinary creations with our premium chili variants today.</p>

                                </div>
                            </MDBCol>
                        </MDBRow>
                    </section>

                    <section className="mainPage">
                        <MDBRow className="mob-d-flex mob-justify-content-center">
                            <MDBCol md={12} lg={6} sm={12} className="d-flex align-items-center justify-content-center flex-column mob-order-2">
                                <div className="posterImage d-flex align-items-center justify-content-center flex-column  p-3">
                                    <h1 className="pb-3">Welcome to Akash Enterprises Your Premier Destination for Premium Red Chilies</h1>
                                    <p>
                                        "Welcome to Akash Enterprises, your premier destination for the finest quality red chilies. With a passion for excellence and a commitment to delivering only the best, we take pride in offering a diverse range of premium red chili products sourced from the finest farms. Our dedication to quality assurance and food safety standards ensures that each chili is packed with flavor and freshness, ready to spice up kitchens around the globe. At Akash Enterprises, we believe in building lasting relationships with our customers, built on trust, reliability, and exceptional service. Explore our selection of red chilies and experience the difference that sets us apart in the world of chili exports."
                                    </p>
                                </div>
                            </MDBCol>
                            <MDBCol md={12} lg={6} sm={12} className="d-flex align-items-center justify-content-center  ">
                                <div className="posterImage d-flex align-items-center justify-content-center mob-order-1">
                                    <MDBCarousel showControls showIndicators>
                                        <MDBCarouselItem itemId={1}>
                                            <img src={shop1} className='d-block w-100' alt='...' />
                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId={2}>
                                            <img src={shop2} className='d-block w-100' alt='...' />
                                        </MDBCarouselItem>
                                        <MDBCarouselItem itemId={3}>
                                            <img src={shop3} className='d-block w-100' alt='...' />
                                        </MDBCarouselItem>
                                    </MDBCarousel>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </section>


                    <section className="section-3 position-relative">
                        <h1 className="mt-5 mob-mt-2 c-title">Our Products</h1>
                        <MDBRow>
                            {products.slice(0, 8).map(product => (
                                <MDBCol md={3} lg={3} sm={12}>
                                    <MDBCard className="Products2" key={product.id}>
                                        <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                            <MDBCardImage src={product.imgSrc} alt={product.name} fluid />
                                            <a>
                                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                            </a>
                                        </MDBRipple>
                                        <MDBCardBody>
                                            <MDBCardTitle>
                                                <strong>
                                                    {product.name}
                                                </strong>
                                            </MDBCardTitle>
                                            <MDBCardText>
                                                <div className="cardTextdesc" title={product.description}>
                                                    {product.description}
                                                </div>

                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </section>
                    <center className="py-5">
                        <Link to="/Products">
                            <MDBBtn className="shadow-0 rounded-0 mob-w-100" size="lg">
                                View All Products
                            </MDBBtn>
                        </Link>
                    </center>

                    <section className="section-4">
                        <div className="pt-4">
                            <p className="m-0 text-secondary">
                                Certifications
                            </p>
                            <h2>
                                We are Certified & Recognized from
                            </h2>
                            <div className="Certifications2 pb-5">
                                <div className="bg-light rounded-2 bg-opacity-45 moblinediv">
                                    <img src={ISO} alt="" />
                                    <img src={Faasi} alt="" />
                                    <img src={SBifrom} alt="" />
                                    <img src={APEDA} alt="" />
                                </div>
                            </div>
                        </div >


                    </section>
                </div>
            </MDBContainer>
            <Footer />
        </>
    )
}
export default LandingPage