import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <React.Fragment>
            <>
                <MDBFooter className='text-center text-lg-start text-muted'>
                    <div className='footer-overlay'>
                        <section className=''>
                            <MDBContainer className='text-center text-md-start mt-5'>
                                <MDBRow className='mt-3'>
                                    <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4 d-flex flex-column align-items-center'>
                                        <h6 className='text-uppercase fw-bold mb-4 text-center'>
                                            <div className='navImg'></div>
                                        </h6>
                                        <p>
                                            your premier destination for the finest chilli variants sourced from around the globe.
                                        </p>
                                    </MDBCol>

                                    <MDBCol md="5" lg="2" xl="2" className='mx-auto mb-4'>
                                        <h6 className='text-uppercase fw-bold mb-4'>Useful Links</h6>
                                        <p>
                                            <a className='text-reset'>
                                                <Link to='/Products'>
                                                    Products
                                                </Link>
                                            </a>
                                        </p>
                                        <p>
                                            <a className='text-reset'>
                                                <Link to='/about'>
                                                    About Us
                                                </Link>
                                            </a>
                                        </p>
                                        <p>
                                            <a className='text-reset'>
                                                <Link to='/contactus'>
                                                    Contact Us
                                                </Link>
                                            </a>
                                        </p>
                                    </MDBCol>
                                    <MDBCol md="5" lg="2" xl="2" className='mx-auto mb-4'>
                                        <h6 className='text-uppercase fw-bold mb-4'>Akash Enterprises</h6>
                                        <p>
                                            <a href="https://www.google.com/maps/search/11-14-131,+Road+No.+15A,+%22O%22+City,+Warangal,+TS+-+506002,+INDIA" target="_blank">
                                                11-14-124, Road No. 14A, "O" City,<br />
                                                Warangal, TS - 506002, INDIA
                                            </a>
                                        </p>
                                    </MDBCol>



                                    <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                                        <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                        <p>
                                            <MDBIcon icon="home" className="me-2" />
                                            Warangal, TS - 506002, INDIA
                                        </p>
                                        <p>

                                            <a href="mailto:akashenterprises0127@gmail.com" className='me-4 '><MDBIcon icon="envelope" className='me-2' />akashenterprises0127@gmail.com</a>
                                        </p>
                                        <p>
                                            <a href="tel:9951331322" className='me-4'><MDBIcon fas icon="phone-alt" className='me-2' />+91 995 133 1322</a>
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </section>

                        <div className='p-4 text-center w-100'>
                            Copyright © 2024 Akash Enterprises : All rights reserved | <a href="https://api.whatsapp.com/send?phone=9848173866" target='_blank'>Website Designed and Developed by Harish Marella</a>
                        </div>
                    </div>
                </MDBFooter>
            </>
        </React.Fragment>
    )
}
