import React, { useState, useEffect } from "react"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
// import ProductData from "../Json/ProductData"
// import Data from "../Json/Data"
import { Data } from "../Json/Data"
import { useLocation, useNavigate } from "react-router";
import ISO from "../Components/Assets/Images/iso-22000.png";
import Faasi from "../Components/Assets/Images/pngegg.png";
import SBifrom from "../Components/Assets/Images/spices-board.jpg";
import APEDA from "../Components/Assets/Images/apeda.png";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRipple
} from 'mdb-react-ui-kit';
import Img1 from "../Components/Assets/Images/pexels-pixabay-60597.jpg";
function Products() {
    const navigate = useNavigate();
    const [products, setProducts] = useState(Data);

    useEffect(() => {
        setProducts(Data);
        window.scrollTo(0, 0);
    }, []);

    function onClickCard(product) {

        navigate("/ProductDetails", {
            state: product,
        });
    }

    return (
        <>
            <Header />
            <MDBContainer fluid>
                <div className="padding-container">
                    <div className="HeroPoster">
                        <p className="m-0 mt-4 text-secondary">Our Chilli Products</p>
                        <h1 className="">Akash Enterprises</h1>
                    </div>
                    <section className="section-4">
                        <div className="     mt-4">
                            {/* <div className="d-flex flex-wrap justify-content-start   pb-5"> */}
                            <MDBRow>
                                {products.map(product => (
                                    <MDBCol md={3} lg={3} sm={12}>
                                        <MDBCard className="Products2" key={product.id} >
                                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' title={product.name}>
                                                <MDBCardImage src={product.imgSrc} alt={product.name} fluid />
                                                <a>
                                                    <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                                </a>
                                            </MDBRipple>
                                            <MDBCardBody>
                                                <MDBCardTitle>
                                                    <strong>
                                                        {product.name}
                                                    </strong>
                                                </MDBCardTitle>
                                                <MDBCardText>
                                                    <div className="cardTextdesc" title={product.description}>
                                                        {product.description}
                                                    </div>
                                                </MDBCardText>
                                                <MDBBtn onClick={(e) => onClickCard(product)} size="lg" className="rounded-0 shadow-0 mob-w-100 mt-3">Know More</MDBBtn>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        </div>
                        {/* </div> */}
                    </section>
                    <div className="pt-4">
                        <p className="m-0 text-secondary">
                            Certifications
                        </p>
                        <h2>
                            We are Certified & Recognized from
                        </h2>
                        <div className="Certifications2 pb-5">
                            <div className="bg-light rounded-2 bg-opacity-45 moblinediv">
                                <img src={ISO} alt="" />
                                <img src={Faasi} alt="" />
                                <img src={SBifrom} alt="" />
                                <img src={APEDA} alt="" />
                            </div>
                        </div>
                    </div >
                </div>



            </MDBContainer>
            <Footer />
        </>
    )
}
export default Products