import React, { useEffect } from "react"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRipple,
    MDBListGroup,
    MDBListGroupItem,
    MDBBadge,
} from 'mdb-react-ui-kit';
import Img1 from "../Components/Assets/Images/pexels-pixabay-60597.jpg";
import transportation from "../Components/Assets/Logos/Logo New.svg";
import shop1 from "../Components/Assets/Godown_Images/1.jpeg";
import shop2 from "../Components/Assets/Godown_Images/7.jpeg";
import shop3 from "../Components/Assets/Godown_Images/8.jpeg";
import shop4 from "../Components/Assets/Godown_Images/4.jpeg";
import Akash from "../Components/Assets/Images/Akash.jpeg";
import ISO from "../Components/Assets/Images/iso-22000.png";
import Faasi from "../Components/Assets/Images/pngegg.png";
import SBifrom from "../Components/Assets/Images/spices-board.jpg";
import APEDA from "../Components/Assets/Images/apeda.png";
import { Link } from "react-router-dom";
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
function AboutPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <MDBContainer fluid>
                <div className="padding-container">
                    <div className="HeroPoster">
                        <p className="m-0 mt-4 text-secondary">About Us</p>
                        <h1 className="">Akash Enterprises</h1>
                    </div>
                    <section className="section">
                        <div className="mt-4 w-100">
                            <MDBRow>
                                <MDBCol md={12} lg={6} sm={12}>
                                    <div>
                                        <p className="text-justify">Akash Enterprises Enterprises is a 2017 established organization committed to delivering premium quality products to its clients. Based in Warangal (Telangana), the organization is involved in exporting varieties of dried red chili, chili seeds, and chili powder to different countries. The offered products are unadulterated, authentic, full of flavor, and have good color. They are also made available at reasonable prices. The organization is introduced in the market by Mr. Kancha Sravan. It is his valuable guidance and support that let the organization grow at an accelerated pace in this competitive market</p>
                                        <p>
                                            <strong>Our Vendor Base: </strong>
                                            We are linked up with some of the trusted and renowned vendors in the market who provide us with the best quality products to support us in keeping our supply chain continued throughout the year. We make sure that the chilies and chili powder that we procure from these vendors are precisely processed in a suitable condition in a contamination-free environment in compliance with the industry-set guidelines. Rigorous quality analysis is conducted at the vendors’ end so as to ensure the quality and authenticity of the products.
                                        </p>
                                        <p>
                                            <strong>Our Storage Unit</strong>
                                            All of the procured products are stored in a temperature-controlled storage room having ample space. We pay special attention to maintaining the cleanliness and hygiene of this unit so as to protect our product stocks from moisture, fungus, microbes, pests, and other possible contaminating things.
                                        </p>

                                        <p><strong>Why Us</strong>
                                            We are fortunate enough that we are assisted by a team of efficient and energetic individuals who handle all of our business activities in a systematic manner. By offering the best quality products at cost-effective prices, maintaining transparency in our dealings, and delivering orders in the promised time period, we are attaining a high level of customer satisfaction.
                                        </p>


                                        <MDBListGroup light className="shadow-3 mt-4">
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start p-3 pe-5'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Name of CEO</div>
                                                </div>
                                                <strong>
                                                    Akash Budima
                                                </strong>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start p-3 pe-5'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Name of Business</div>
                                                    <span className="text-secondary">
                                                        The Emporium of Elegance
                                                    </span>
                                                </div>
                                                <strong>
                                                    Exporter
                                                </strong>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start p-3 pe-5'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Number of Employees</div>
                                                    <span className="text-secondary">
                                                        Team Strength: 20 dedicated professionals.
                                                    </span>
                                                </div>
                                                <strong>
                                                    20
                                                </strong>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start p-3 pe-5'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Year of Establishment</div>
                                                    <span className="text-secondary">
                                                        Since 2017
                                                    </span>
                                                </div>
                                                <strong>
                                                    2017
                                                </strong>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start p-3 pe-5'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Market Covered</div>
                                                    <span className="text-secondary">World</span>
                                                </div>
                                                <strong>
                                                    World Wide
                                                </strong>
                                            </MDBListGroupItem>
                                        </MDBListGroup>


                                    </div>
                                </MDBCol>
                                <MDBCol md={12} lg={6} sm={12}>
                                    <div className="aboutUsImg">
                                        <MDBCarousel showControls showIndicators>
                                            <MDBCarouselItem itemId={1}>
                                                <img src={shop1} className='d-block w-100' alt='...' />
                                            </MDBCarouselItem>
                                            <MDBCarouselItem itemId={2}>
                                                <img src={shop2} className='d-block w-100' alt='...' />
                                            </MDBCarouselItem>
                                            <MDBCarouselItem itemId={3}>
                                                <img src={shop3} className='d-block w-100' alt='...' />
                                            </MDBCarouselItem>
                                        </MDBCarousel>

                                        <p className=" mt-3 mob-my-3">
                                            Established in 2017, Akash Enterprises is dedicated to providing premium quality red chilies, chili seeds, and chili powder to global markets. Led by Mr. Kancha Sravan, our organization thrives on his invaluable guidance, propelling us to success in the competitive industry. We boast a network of trusted vendors who supply us with top-notch products, ensuring a continuous and reliable supply chain year-round. Our rigorous quality control measures guarantee the authenticity and purity of our offerings, maintaining industry standards.

                                            Our state-of-the-art storage facilities ensure optimal conditions for product preservation, safeguarding against moisture, microbes, and pests. What sets us apart is our dedicated team of professionals, tirelessly ensuring seamless operations and customer satisfaction. Through transparent dealings and timely delivery, we prioritize customer needs, earning trust and loyalty. At Akash Enterprises, we're not just exporters; we're purveyors of quality and integrity, committed to elevating the standards of the chili export industry.
                                        </p>


                                        <MDBRow>
                                            <MDBCol xl={12} lg={12} className='mb-4'>
                                                <MDBCard>
                                                    <MDBCardBody>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <img
                                                                src={Akash}
                                                                alt=''
                                                                style={{ width: '100px', height: '100px' }}
                                                                className='rounded-circle'
                                                            />
                                                            <div className='ms-3'>
                                                                <p className='fw-bold mb-1 d-flex align-items-center'>Akash Budima<MDBBadge light className="d-flex align-items-center ms-2">CEO</MDBBadge></p>
                                                                <p className='text-muted mb-0 text-break'>akashenterprises0127@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol>
                                            {/* <MDBCol xl={6} lg={6} className='mb-4'>
                                                <MDBCard>
                                                    <MDBCardBody>
                                                        <div className='d-flex align-items-center'>
                                                            <img
                                                                src='https://mdbootstrap.com/img/new/avatars/6.jpg'
                                                                alt=''
                                                                style={{ width: '100px', height: '100px' }}
                                                                className='rounded-circle'
                                                            />
                                                            <div className='ms-3'>
                                                                <p className='fw-bold mb-1'>Alex Ray</p>
                                                                <p className='text-muted mb-0'>alex.ray@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBCol> */}
                                        </MDBRow>
                                    </div>

                                </MDBCol>
                            </MDBRow>
                        </div>
                    </section>
                    <div className="pt-4">
                        <h2>
                            Our Transportation Service
                        </h2>
                    </div>
                    <Link to="https://www.dpworld.com/" target="_blank">
                        <div className="bg-dark p-4 d-inline-block mt-2 rounded-5">
                            <img src={transportation} alt="" />
                        </div>
                    </Link>

                    <section className="section-4">
                        <div className="pt-4">
                            <p className="m-0 text-secondary">
                                Certifications
                            </p>
                            <h2>
                                We are Certified & Recognized from
                            </h2>
                            <div className="Certifications2 pb-5">
                                <div className="bg-light rounded-2 bg-opacity-45 moblinediv">
                                    <img src={ISO} alt="" />
                                    <img src={Faasi} alt="" />
                                    <img src={SBifrom} alt="" />
                                    <img src={APEDA} alt="" />
                                </div>
                            </div>
                        </div >
                    </section>


                </div>
            </MDBContainer >
            <Footer />
        </>
    )
}

export default AboutPage









