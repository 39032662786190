import React, { useState, useEffect } from "react"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import ProductData from "../Json/ProductData"
import { useLocation, useNavigate, } from "react-router";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRipple,
    MDBIcon,
    MDBBadge,
    MDBListGroup,
    MDBListGroupItem,
    MDBAccordion,
    MDBAccordionItem,
    MDBTable, MDBTableHead, MDBTableBody
} from 'mdb-react-ui-kit';
import Img1 from "../Components/Assets/Images/pexels-pixabay-60597.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faWheatAwn, faCloudSun, faChartLine, faPepperHot, faBoxOpen, faDroplet, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

import ISO from "../Components/Assets/Images/iso-22000.png";
import Faasi from "../Components/Assets/Images/pngegg.png";
import SBifrom from "../Components/Assets/Images/spices-board.jpg";
import APEDA from "../Components/Assets/Images/apeda.png";
function ProductDetails() {
    let location = useLocation();
    const Data = location.state;
    console.log(Data);
    const handleClick = () => {
        window.history.back(); // This will navigate back in the browser's history
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the route changes
    }, []);

    return (
        <>
            <Header />
            <MDBContainer fluid>
                <div className="padding-container">
                    <section className="section">
                        <div className="w-100 py-5 mob-py-5">
                            <MDBRow>
                                <MDBCol md={3}>
                                    <div className="d-flex flex-column align-items-center ProductcardImage ">
                                        <MDBCard>
                                            <MDBCardBody>
                                                <div className="ProductView-Img">
                                                    <img src={Data.imgSrc} alt="" />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                        {/* <div className="whatsapp w-100">
                                            <MDBRipple rippleColor='success' className="bg-success p-2 text-dark bg-opacity-25 my-3 w-50 rounded d-flex align-items-center justify-content-center cursor-pointer">
                                                <MDBIcon fab icon="whatsapp" color="success" className="fs-4 fw-bold me-2" />
                                                <strong className="text-success">
                                                    Send Message
                                                </strong>
                                            </MDBRipple>
                                        </div> */}
                                    </div>
                                </MDBCol>
                                <MDBCol md={9}>
                                    <MDBCard>
                                        <MDBCardBody>
                                            <div onClick={handleClick} className="cursor-pointer text-secondary">
                                                <MDBIcon fas icon="arrow-left" className="fs-6 me-2" color="secondary" />Back to Products
                                            </div>
                                            <div>
                                                <div className="mt-4">
                                                    <span className="text-secondary">Product Details</span>
                                                    <div>
                                                        <h2 className="me-2">{Data.name}</h2>
                                                    </div>
                                                    <div className="d-flex">
                                                        <small></small>
                                                    </div>

                                                    <div className="position-relative">
                                                        <MDBRow>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3">
                                                                                <MDBIcon fas icon="map-marker-alt" className="fs-4" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Country Of Origin</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.countryOfOrigin}</strong> <MDBIcon flag={Data.countryOfOrigin} className="ms-2" /></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3">
                                                                                <FontAwesomeIcon icon={faWheatAwn} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Cultivation Type</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.cultivationType}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faCloudSun} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Drying Process</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.dryingProcess}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faStar} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Quality</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.quality}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faChartLine} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Business Type</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.businessType}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>
                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faPepperHot} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Taste</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.taste}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>

                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faDroplet} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Color</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.color}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>

                                                            <MDBCol xl={3} lg={6} className='mb-4'>
                                                                <MDBCard>
                                                                    <MDBCardBody>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div class="badge square  badge-primary p-3 ">
                                                                                <FontAwesomeIcon icon={faBoxOpen} className="fs-4 text-primary" />
                                                                            </div>
                                                                            <div className='ms-3'>
                                                                                <p className='fw-bold mb-1'>Packing Type</p>
                                                                                <p className='text-muted mb-0 text-capitalize fs-6'><strong>{Data.packingType}</strong></p>
                                                                            </div>
                                                                        </div>
                                                                    </MDBCardBody>
                                                                </MDBCard>
                                                            </MDBCol>

                                                        </MDBRow>



                                                    </div>


                                                    <div>
                                                        <p>
                                                            <b>
                                                                Description: &nbsp;
                                                            </b>
                                                            {Data.description}
                                                        </p>
                                                    </div>

                                                    <a href="https://api.whatsapp.com/send?phone=9951331322" target='_blank'>
                                                        <MDBBtn className="rounded-0 shadow-0 mob-w-100 mt-3">
                                                            Product Inquiry
                                                        </MDBBtn>
                                                    </a>

                                                    <div className="routing-border"></div>
                                                    <div className=" w-mob-100 mt-4">
                                                        <b className="fs-5">
                                                            Product Specification:
                                                        </b>
                                                        <p>Specifications shall basis the variety. The following area specification for 334 dry red chilies according to the Andhra Pradesh, India origin.</p>
                                                        <MDBTable bordered striped>
                                                            <MDBTableHead>
                                                                <tr>
                                                                    <th scope='col'>
                                                                        <b>
                                                                            Name
                                                                        </b>
                                                                    </th>
                                                                    <th scope='col'>{Data.name}</th>
                                                                </tr>
                                                            </MDBTableHead>
                                                            <MDBTableBody>
                                                                <tr>
                                                                    <th scope='row'>Country Of Origin</th>
                                                                    <td>{Data.countryOfOrigin}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Cultivation Type</th>
                                                                    <td>{Data.cultivationType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Drying Process</th>
                                                                    <td>{Data.dryingProcess}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Quality</th>
                                                                    <td>{Data.quality}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Business Type</th>
                                                                    <td>{Data.businessType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Taste</th>
                                                                    <td>{Data.taste}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Specialities</th>
                                                                    <td>{Data.specialities}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Packing Type</th>
                                                                    <td>{Data.packingType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Color</th>
                                                                    <td>{Data.color}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Skin</th>
                                                                    <td>{Data.skin}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Pungency In SHU</th>
                                                                    <td>{Data.pungencyInSHU}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Colour In ASTA</th>
                                                                    <td>{Data.colourInAsta}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Length</th>
                                                                    <td>{Data.length}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Breath</th>
                                                                    <td>{Data.breath}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Capsaicin Content</th>
                                                                    <td>{Data.capsaicinContent}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Moisture</th>
                                                                    <td>{Data.moisture}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope='row'>Contact Details</th>
                                                                    <td>
                                                                        AKASH BUDIMA, <br />
                                                                        akashenterprises0127@gmail.com<br />
                                                                        +91 995 133 1322
                                                                    </td>
                                                                </tr>
                                                            </MDBTableBody>
                                                        </MDBTable>
                                                    </div>





                                                </div>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </section>
                    <div className="pt-4">
                        <p className="m-0 text-secondary">
                            Certifications
                        </p>
                        <h2>
                            We are Certified & Recognized from
                        </h2>
                        <div className="Certifications2 pb-5">
                            <div className="bg-light rounded-2 bg-opacity-45 moblinediv ">
                                <img src={ISO} alt="" />
                                <img src={Faasi} alt="" />
                                <img src={SBifrom} alt="" />
                                <img src={APEDA} alt="" />
                            </div>
                        </div>
                    </div >

                </div >
            </MDBContainer >
            <Footer />
        </>
    )
}
export default ProductDetails