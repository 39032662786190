import React, { useEffect } from "react"
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBRipple,
    MDBTextArea,
    MDBIcon
} from 'mdb-react-ui-kit';
import Img1 from "../Components/Assets/Images/pexels-pixabay-60597.jpg";
function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the route changes
    }, []);


    return (
        <>
            <Header />
            <MDBContainer fluid>
                <div className="padding-container">
                    <div className="HeroPoster">
                        <p className="m-0 mt-4 text-secondary">Get In Touch</p>
                        <h1 className="">Akash Enterprises</h1>
                    </div>
                    <section className="">
                        <div className="mt-4">
                            <div className="w-100 mt-4">
                                <MDBRow>
                                    <MDBCol md={12} lg={6} sm={12}>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardTitle>Contact Us</MDBCardTitle>
                                                <MDBCardText className="p-2">
                                                    <div className="d-flex w-100 w-mob-100 d-mob-block ">
                                                        <div className="w-50 w-mob-100">
                                                            <strong>Full Name</strong>
                                                            <MDBInput size="lg" className="mt-2" placeholder="Name" />
                                                        </div>
                                                        <div className="ms-2 w-50 w-mob-100 mob-mt-4 mob-ms-0">
                                                            <strong>Email Address</strong>
                                                            <MDBInput placeholder="Email" size="lg" className="mt-2" />
                                                        </div>
                                                    </div>

                                                    <div className="w-100 mt-4">
                                                        <strong>Subject</strong>
                                                        <MDBInput size="lg" className="mt-2" placeholder="subject" />
                                                    </div>

                                                    <div className="w-100 mt-4">
                                                        <strong>Message</strong>
                                                        <MDBTextArea size="lg" className="mt-2" id='textAreaExample' placeholder="Message" rows={4} />
                                                    </div>
                                                </MDBCardText>
                                                <MDBBtn className="mob-w-100 shadow-0 rounded-0">Send Message</MDBBtn>
                                            </MDBCardBody>
                                        </MDBCard>


                                    </MDBCol>
                                    <MDBCol md={12} lg={6} sm={12}>
                                        <MDBCard className="mob-mt-3">
                                            <MDBCardBody>
                                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d15177.447663441439!2d79.645245!3d18.0084!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDAwJzMwLjIiTiA3OcKwMzgnNTIuMiJF!5e0!3m2!1sen!2sin!4v1708061748730!5m2!1sen!2sin" width="100%" height="480" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.3594336452593!2d79.648051!3d18.008515299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3345923e5ccb0b%3A0x72950e2f0cd91295!2sAkash%20Enterprises!5e0!3m2!1sen!2sin!4v1714111768503!5m2!1sen!2sin" width="100%" height="480" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d7589.219969613874!2d79.6604970769199!3d17.99687348432862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDAwJzExLjYiTiA3OcKwMzknMjEuOSJF!5e0!3m2!1sen!2sin!4v1712056256128!5m2!1sen!2sin" width="100%" height="480" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>


                                <div className="mt-5">
                                    <MDBCard>
                                        <MDBCardBody>
                                            <div className="d-flex align-items-center justify-content-around flex-wrap mob-d-block">
                                                <div className="d-flex justify-content-center align-items-center flex-column mob-mt-3">
                                                    <div class="badge square  badge-primary p-4 rounded-circle">
                                                        <MDBIcon fas icon="map-marker-alt" className="fs-4" />
                                                    </div>
                                                    <strong className="mt-2">Address</strong>
                                                    <a href="https://maps.app.goo.gl/8zDUioaQ1U7WVbGY7" target="_blank" className="text-center">
                                                        Gisgonda, Telangana,<br />
                                                        Warangal, TS - 506002, INDIA
                                                    </a>

                                                </div>
                                                <div className="d-flex justify-content-center align-items-center flex-column mob-mt-3">
                                                    <div class="badge square  badge-primary p-4 rounded-circle">
                                                        <MDBIcon fas icon="phone-alt" className="fs-4" />
                                                    </div>
                                                    <strong className="mt-2">Phone</strong>
                                                    <a href="tel:9951331322">+91 995 133 1322</a>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center flex-column mob-mt-3">
                                                    <div class="badge square  badge-primary p-4 rounded-circle">
                                                        <MDBIcon fab icon="telegram-plane" className="fs-4" />
                                                    </div>
                                                    <strong className="mt-2">Email</strong>
                                                    <a href="mailto:akashenterprises0127@gmail.com" target="_blank">akashenterprises0127@gmail.com</a>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center flex-column mob-mt-3">
                                                    <div class="badge square  badge-primary p-4 rounded-circle">
                                                        <MDBIcon fas icon="globe-asia" className="fs-4" />
                                                    </div>
                                                    <strong className="mt-2">Akash Enterprises</strong>
                                                    <a href="/">www.akashenterprise.co.in</a>
                                                </div>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </MDBContainer>
            <Footer />
        </>
    )
}
export default ContactUs