import Image from "../Components/Assets/Images/slider2.jpg"
import Image2 from "../Components/Assets/Images/Slider1.jpg"
import S17_Teja_Image from "../Components/Assets/Images/s17teja.jpg";
import Wonder_Hot_Image from "../Components/Assets/Images/wonderhot.jpg";
import Img_341 from "../Components/Assets/Images/341.jpg";
import Img_5531 from "../Components/Assets/Images/5531.jpg";
import Img_334_SANNAM from "../Components/Assets/Images/334.jpg";
import Img_273 from "../Components/Assets/Images/273.jpeg";
import Img_TOMATO from "../Components/Assets/Images/tomato.jpg";
import Img_C5_ENDO5 from "../Components/Assets/Images/c5.jpeg";
import Img_DEEPIKA_INDICA from "../Components/Assets/Images/deepika.png";
import Img_Teja_Stemless from "../Components/Assets/Images/s17tejastemless.jpg";
import Img_Wonder_Hot_Stemless from "../Components/Assets/Images/wonderhotstemless.jpg";
import Img_341_Stemless from "../Components/Assets/Images/341stemless.jpeg";
import Img_334_SANNAM_Stemless from "../Components/Assets/Images/334sannamstemless.jpg";
import Img_5531_Stemless from "../Components/Assets/Images/5531stemless.jpeg";
import s17tejafatki from '../Components/Assets/Images/s17tejafatki.jpg';
import wonderhotfatki from '../Components/Assets/Images/wonderhotfatki.jpeg';
import chilli341fatki from '../Components/Assets/Images/341fatki.jpeg';



export const Data = [
    {
        id: '0001',
        name: 'S17 Teja',
        imgSrc: S17_Teja_Image,
        description: 'Teja or S17 is one of the hottest varieties of red chillies produced in India. (GI tag not accorded yet.) The chilli is known and liked across the country for its fierce hot flavor and rich aroma. Southern India is the main region of Teja or S17 red chilli production. It has a capsaicin content of 0.50-0.70% making it more pungent and spicy. The huge demand for Oleoresin, a natural chilli extract, is mainly driving the export of Teja variety to various spice processing industries in several Asian countries.',
        countryOfOrigin: 'india',
        quality: 'BEST',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '75000-100000',
        colourInAsta: '40-60',
        length: '5-6 CM',
        breath: '0.8-1.3 CM',
        capsaicinContent: '0.50%-70%',
        moisture: '14%'
    },
    {
        name: 'Wonder Hot',
        imgSrc: Wonder_Hot_Image,
        description: 'Wonder Hot Red Chillies are popular over the globe due to its unique feature of long size, thick skin and low heat value. Wonder hot is widely used for making red chilli powder because the yield of the powder is very high and the colour is bright red which attract the consumers at first sight.',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '7000-9000',
        colourInAsta: '70-110',
        length: '15-16 CM',
        breath: '1-1.5 CM',
        capsaicinContent: '0.2%-0.23%',
        moisture: '14%'
    },
    {
        name: '341',
        imgSrc: Img_341,
        description: 'US-341 Red Chilli is a variety mainly preferred in the Red Chilli Powder manufacturers as the Colour value and Scoville Heat Units are better than the conventional varieties like the S4 - Sannam and S-17 - Teja. US-341 Red Chillies are majorly consumed by Masala Companies across the World.',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '35000-45000',
        colourInAsta: '80-110',
        length: '8-10 CM',
        breath: '1-1.2 CM',
        capsaicinContent: '0.30%-0.45%',
        moisture: '14%'
    },
    {
        name: '5531',
        imgSrc: Img_5531,
        description: 'Syngenta HPH-5531 is a hybrid hot pepper variety that is medium pungent and deep red in color. It is also known as Mirch, Menasinakai, Mirapa, and Milakay.',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thick',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '23000-30000',
        colourInAsta: '90-120',
        length: '6-11 CM',
        breath: '1-1.2 CM',
        capsaicinContent: '0.20%-40%',
        moisture: '14%'
    },
    {
        name: '334,SANNAM',
        imgSrc: Img_334_SANNAM,
        description: 'Sannam also known as 334, S4 or sometimes as S10 is one of the most popular variants of the red chillies that is highly in demand because of its aroma, flavor and pungency. Nearly 50% of chilli production in India counts for Sannam dried red chillies.',
        countryOfOrigin: 'india',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '25000-30000',
        colourInAsta: '50-70',
        length: '5-7 CM',
        breath: '1.2-2 CM',
        capsaicinContent: '0.2%-0.27%',
        moisture: '14%'
    },
    {
        name: '273',
        imgSrc: Img_273,
        description: '273 Wrinkle Dried Red Chillies is one of the most famous type of Chillies and has an overwhelming demand throughout the World for making Chilli Powder. It is also known as Semi Wrinkled Chilli .',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '20000-25000',
        colourInAsta: '80-110',
        length: '10-12 CM',
        breath: '1-1.1 CM',
        capsaicinContent: '0.5-0.726%',
        moisture: '14%'
    },
    {
        name: 'Tomato',
        imgSrc: Img_TOMATO,
        description: 'The Warangal-only red-dried chapata chilli, also known as "Tomato Chapata Warangal Chilli," has a look similar to Mexican capsicum. It is grown in nearby fields that have easy access to water. The green capsicum is dried to create the red chilli variety. The Scoville Scale, a tool used to measure how hot a pepper is, gives this chilli an extremely low heat or pungency score. 4,000 to 8,000 SHU is the heat value, compared to 16 million SHU for pure capsaicin.',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thick',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '4000-5000',
        colourInAsta: '60-90',
        length: '5-7 CM',
        breath: '3-5 CM',
        capsaicinContent: '0.1-0.26%',
        moisture: '14%'
    },
    {
        name: 'C5,ENDO5',
        imgSrc: Img_C5_ENDO5,
        description: `C5,ENDO5, also known as "Red Organic Spicy Chilli," is a premium variety cultivated in the fertile lands of India. Grown under organic practices with careful attention to quality, these chillies boast a vibrant red color and a thick skin, ensuring durability and flavor retention. The cultivation process involves sun drying, enhancing the natural spicy taste cherished by culinary enthusiasts. With a pungency ranging from 4000 to 5000 SHU (Scoville Heat Units) and a rich, spicy flavor profile, these chillies add a distinctive kick to dishes. As an exporter's choice, C5,ENDO5 is renowned for its consistent quality and taste. Packed in 10, 25, 35, or 40 KG jute/PP bags, these chillies are rich in taste, with good quality assured. Additionally, they exhibit a maximum color intensity of 80-110 ASTA units, ensuring visual appeal. With a length of 8-10 CM and a breadth of 1-1.5 CM, these chillies are optimal for various culinary applications. Their capsaicin content ranges from 0.30% to 0.45%, contributing to their characteristic spiciness, while maintaining a moisture level of 14%, preserving freshness and shelf life. C5,ENDO5 is the perfect choice for discerning chefs and consumers seeking premium organic chillies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thick',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '4000-5000',
        colourInAsta: '80-110 max',
        length: '8-10 CM',
        breath: '1-1.5 CM',
        capsaicinContent: '0.30%-0.45%',
        moisture: '14%'
    },
    {
        name: 'DEEPIKA , INDICA',
        imgSrc: Img_DEEPIKA_INDICA,
        description: `DEEPIKA, INDICA, a top-quality organic red chilli from India, is renowned for its thick-skinned, sun-dried pods. With a spicy taste and rich flavor profile, it's perfect for culinary use. Ideal for export, it comes in 10-40 KG jute/PP packaging, boasting a pungency of 15,000-200,000 SHU and a vibrant red color. Ranging from 10-12 CM in length and 1.5-2 CM in breadth, it contains 0.2-0.4% capsaicin and maintains a moisture level of 14%, ensuring premium quality.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thick',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '15000-200000',
        colourInAsta: '70-100',
        length: '10-12CM',
        breath: '1.5-2 CM',
        capsaicinContent: '0.2-0.4%',
        moisture: '14%'
    },
    {
        name: 'Teja Stemless',
        imgSrc: Img_Teja_Stemless,
        description: 'The S17 Teja chilli is the most spiciest variety available in India. The red factor and the compelling taste in our food is enhanced by Teja S17. The major world cuisines like Chinese, American, Thai, Italian, Japanese and Indian use this quality to enrich the taste, colour and smell of the food. It is also used in the making of chilli powder and is rich in various vitamins and minerals.',
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '75000-100000',
        colourInAsta: '40-60',
        length: '4-5 CM',
        breath: '0.8-1.3 CM',
        capsaicinContent: '0.50%-70%',
        moisture: '14%'
    },
    {
        name: 'Wonder Hot Stemless',
        imgSrc: Img_Wonder_Hot_Stemless,
        description: `Introducing Wonder Hot Stemless, a premium organic red chilli originating from India. With a thin skin and sun-dried cultivation, it packs a spicy punch that's perfect for culinary ventures. Tailored for export, it comes in robust 10-40 KG jute/PP packaging. Boasting a pungency of 70,000-90,000 SHU and a vivid red hue with 70-110 ASTA color units, it measures 12-13 CM in length and 1-1.5 CM in breadth. Its capsaicin content ranges from 0.2% to 0.23%, maintaining a moisture level of 14% for optimal freshness. Wonder Hot Stemless guarantees rich taste and superior quality for discerning consumers.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '70000-90000',
        colourInAsta: '70-110',
        length: '12-13 CM',
        breath: '1-1.5 CM',
        capsaicinContent: '0.2%-0.23%',
        moisture: '14%'
    },
    {
        name: '341 Stemless',
        imgSrc: Img_341_Stemless,
        description: `Introducing 341 Stemless, an exquisite organic red chilli hailing from India. Characterized by its thin skin and sun-dried cultivation, it offers a delightful spicy flavor that excites the palate. Tailored for export, it is available in sturdy 10-40 KG jute/PP packaging, ensuring its freshness and quality. With a pungency ranging from 35,000 to 45,000 SHU and a vibrant red color measuring 80-110 ASTA units, it is a hallmark of excellence. Measuring up to 5-6 CM in length and 1-1.2 CM in breadth, it contains 0.30% to 0.45% capsaicin and maintains a moisture level of 14%, guaranteeing superior taste and quality. 341 Stemless is the perfect choice for discerning consumers seeking premium organic chillies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '35000-45000',
        colourInAsta: '80-110',
        length: '5-6 max',
        breath: '1-1.2 CM',
        capsaicinContent: '0.30%-0.45%',
        moisture: '14%'
    },
    {
        name: '334,SANNAM Stemless',
        imgSrc: Img_334_SANNAM_Stemless,
        description: `Introducing 334, SANNAM Stemless, a premium organic red chilli originating from India. With its thin skin and traditional sun-dried cultivation, it delivers a spicy kick that delights the senses. Tailored for export, it is available in robust 10-40 KG jute/PP packaging, ensuring freshness and quality. With a heat level ranging from 25,000 to 100,000 SHU and a rich red color measuring 50-70 ASTA units, it embodies excellence. Measuring 4-5 CM in length and 1.1-2 CM in breadth, it contains 0.2% to 0.27% capsaicin and maintains a moisture level of 14%, guaranteeing superior taste and quality. 334, SANNAM Stemless is the ideal choice for discerning consumers seeking premium organic chillies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '25000-100000 (Heat)',
        colourInAsta: '50-70',
        length: '4-5 CM',
        breath: '1.1-2 CM',
        capsaicinContent: '0.2-0.27%',
        moisture: '14%'
    },
    {
        name: '5531 Stemless',
        imgSrc: Img_5531_Stemless,
        description: `Introducing 5531 Stemless, an exceptional organic red chilli originating from India. With its thick skin and traditional sun-dried cultivation, it offers a robust spicy flavor that tantalizes the taste buds. Tailored for export, it is available in durable 10-40 KG jute/PP packaging, ensuring freshness and quality. With a pungency ranging from 23,000 to 30,000 SHU and a vibrant red color measuring 90-120 ASTA units, it exemplifies excellence. Measuring 7-9 CM in length and 1-1.2 CM in breadth, it contains 0.20% to 0.40% capsaicin and maintains a moisture level of 14%, ensuring superior taste and quality. 5531 Stemless is the perfect choice for discerning consumers seeking premium organic chillies with a bold flavor profile.        `,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'Red',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thick',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '23000-30000',
        colourInAsta: '90-120',
        length: '7-9 CM',
        breath: '1-1.2 CM',
        capsaicinContent: '0.20-0.40%',
        moisture: '14%'
    },
    {
        name: 'Teja fatki',
        imgSrc: s17tejafatki,
        description: `Introducing Teja Fatki, a premium organic chili originating from India. With its thin skin and traditional sun-dried cultivation, it delivers a spicy kick that ignites the taste buds. Tailored for export, it comes in durable 10-40 KG jute/PP packaging, ensuring freshness and quality. With a pungency ranging from 40,000 to 450,000 SHU and a unique yellowish-red color measuring 15-20 ASTA units, it stands out for its excellence. Measuring 3-5 CM in length and 0.8-1 CM in breadth, it contains 0.2% to 0.5% capsaicin and maintains a moisture level of 14%, guaranteeing superior taste and quality. Teja Fatki is the ideal choice for discerning consumers seeking premium organic chilies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'YELLOWISH RED',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '40000-450000',
        colourInAsta: '15-20',
        length: '3-5 CM',
        breath: '0.8-1 CM',
        capsaicinContent: '0.2-0.5%',
        moisture: '14%'
    },
    {
        name: 'Wonder hot fatki',
        imgSrc: wonderhotfatki,
        description: `Introducing Wonder Hot Fatki, a premium organic chili originating from India. With its thin skin and traditional sun-dried cultivation, it delivers a delightful spicy flavor that excites the taste buds. Tailored for export, it comes in robust 10-40 KG jute/PP packaging, ensuring freshness and quality. With a mild pungency ranging from 2000 to 4000 SHU and a distinctive yellowish-red color measuring 20-40 ASTA units, it stands out for its excellence. Measuring 10-13 CM in length and 1-1.2 CM in breadth, it contains 0.1% to 0.2% capsaicin and maintains a moisture level of 14%, guaranteeing superior taste and quality. Wonder Hot Fatki is the perfect choice for discerning consumers seeking premium organic chilies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'YELLOWISH RED',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '2000-4000',
        colourInAsta: '20-40',
        length: '10-13 CM',
        breath: '1-1.2 CM',
        capsaicinContent: '0.1-0.2%',
        moisture: '14%'
    },
    {
        name: '341 fatki',
        imgSrc: chilli341fatki,
        description: `
        Introducing 341 Fatki, a premium organic chili hailing from India. With its thin skin and traditional sun-dried cultivation, it delivers a tantalizing spicy flavor that excites the palate. Tailored for export, it comes in sturdy 10-40 KG jute/PP packaging, ensuring freshness and quality. With a moderate pungency ranging from 15,000 to 20,000 SHU and a unique yellowish-red color measuring 40-60 ASTA units, it exemplifies excellence. Measuring 5-7 CM in length and 1-1.6 CM in breadth, it contains 0.2% to 0.3% capsaicin and maintains a moisture level of 14%, ensuring superior taste and quality. 341 Fatki is the ideal choice for discerning consumers seeking premium organic chilies with a bold flavor profile.`,
        countryOfOrigin: 'india',
        quality: 'Best',
        color: 'YELLOWISH RED',
        cultivationType: 'Organic',
        dryingProcess: 'Sun Dry',
        skin: 'Thin',
        taste: 'Spicy',
        businessType: 'Exporter',
        specialities: 'Rich in Taste Good Quality',
        packingType: '10,25,35,40 KG JUTE/PP',
        pungencyInSHU: '15000-20000',
        colourInAsta: '40-60',
        length: '5-7 CM',
        breath: '1-1.6 CM',
        capsaicinContent: '0.2-0.3%',
        moisture: '14%'
    }
]
