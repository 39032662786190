import React, { useState } from 'react';
import DarkMode from '../DarkMode/DarkMode';
import { Link, useLocation } from "react-router-dom";
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse
} from 'mdb-react-ui-kit';

export default function Header() {
    const [showNavRight, setShowNavRight] = useState(false);
    const location = useLocation();

    return (
        <MDBNavbar expand='lg'>
            <MDBNavbarBrand>
                <Link to='/'>
                    <div className='navImg'></div>
                </Link>
            </MDBNavbarBrand>

            <div>
                <MDBNavbarToggler
                    type='button'
                    data-target='#navbarRightAlignExample'
                    aria-controls='navbarRightAlignExample'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShowNavRight(!showNavRight)}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>
            </div>

            <MDBCollapse navbar open={showNavRight}>
                <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
                    <MDBNavbarItem>
                        <MDBNavbarLink active={location.pathname === '/'} aria-current='page'>
                            <Link to='/'>
                                Home
                            </Link>
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink active={location.pathname === '/Products'}>
                            <Link to='/Products'>
                                Products
                            </Link>
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink active={location.pathname === '/about'}>
                            <Link to='/about'>
                                About Us
                            </Link>
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <MDBNavbarLink active={location.pathname === '/contactus'}>
                            <Link to='/contactus'>
                                Contact Us
                            </Link>
                        </MDBNavbarLink>
                    </MDBNavbarItem>

                    <MDBNavbarItem>
                        <div className="mb-2">
                            <DarkMode />
                        </div>
                    </MDBNavbarItem>
                </MDBNavbarNav>

                <div className='w-60 nav-search'>
                    <div>
                        <a href="tel:9958452585" className='me-4'><MDBIcon fas icon="phone-alt" color='white' className='me-2' />+91 995 133 1322</a>
                        <a href="mailto:akashenterprises0127@gmail.com" className='me-4 '><MDBIcon far icon="envelope" color='white' className='me-2' />akashenterprises0127@gmail.com</a>
                    </div>
                    <div className='d-flex' >
                        <a href="https://api.whatsapp.com/send?phone=9951331322" target='_blank'>
                            <div title='Hello'>
                                <MDBIcon fab icon="whatsapp" className='me-4 fs-5' color='white' />
                            </div>
                        </a>
                        <div>
                            <MDBIcon fab icon="instagram" className='me-4 fs-5' color='white' />
                        </div>
                        <div>
                            <MDBIcon fab icon="facebook" className='me-4 fs-5' color='white' />
                        </div>
                    </div>
                </div>
            </MDBCollapse>
        </MDBNavbar>
    );
}
