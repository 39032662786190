import React from 'react';
import "./Components/Css/Home.css"
import "./Components/Css/Responsive.css"
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from './Views/LandingPage';
import Products from './Views/Products';
import AboutPage from './Views/AboutPage';
import ContactUs from './Views/ContactUs';
import ProductDetails from './Views/ProductDetails';
function App() {
  return (
    <>
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/ProductDetails" element={<ProductDetails />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
